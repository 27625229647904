import styled from 'styled-components'

export const MessageStyle = styled.h1`
  text-align: center;
  font-size: 2rem;
  color: crimson;
  transform: translate(-50%, 0%);
  position: absolute;
  left: 50%;
  top: 40%;
`
