import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`
export const Abilitie = styled.div`
  background-color: #f4f4f4;
  border-radius: 3px;
  padding: 5px;
  margin: 3px;
  font-size: 1rem;
  text-transform: capitalize;
`
